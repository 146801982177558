var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "operproccess__coming"
    }
  }, [_c('excel-import', {
    attrs: {
      "file": _vm.file
    }
  }), _c('modal-print-price-tags', {
    attrs: {
      "products": _vm.printProducts
    }
  }), _c('egais-info-modal', {
    attrs: {
      "head": _vm.currentHead
    }
  }), _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('documents-header', {
    attrs: {
      "filters": "",
      "page_type": "incoming",
      "set_file": _vm.setFile,
      "choose_files": _vm.chooseFiles
    },
    on: {
      "date_range": _vm.updateDateRange,
      "update_filters": _vm.update_filters
    }
  }), _c('div', {
    staticClass: "b-table__content"
  }, [_c('documents-table-filter', {
    ref: "doc_table_filter",
    attrs: {
      "total": _vm.total,
      "selected_sum": _vm.selected_sum,
      "table_name": "incoming"
    },
    on: {
      "handler_pagination": _vm.handlerSearch,
      "clear": _vm.clearSearchStr
    }
  }), _c('incoming-documents-table', {
    ref: "incoming-documents-table",
    attrs: {
      "is_clear_selected": _vm.isClearSelected,
      "fields": _vm.fields,
      "items_total": _vm.items_total,
      "items": _vm.document_list,
      "table_is_busy": _vm.tableIsBusy,
      "page_type": "incoming"
    },
    on: {
      "select": _vm.setSelected,
      "showEgaisModal": _vm.showEgaisModal,
      "getLinkedHeads": _vm.getLinkedHeads,
      "removeLincked": _vm.removeLincked
    }
  })], 1), _c('table-navbar', {
    attrs: {
      "items": _vm.getSelectedArr,
      "hide_print": "",
      "items_name": "documents",
      "doc_type": "incoming",
      "cancel_ttn": _vm.has_egais,
      "clear_selected": _vm.clearSelected
    },
    on: {
      "print_price_tags": _vm.printPriceTags,
      "remove_items": _vm.remove_items,
      "cancelTtn": _vm.cancel_ttn,
      "copyHead": _vm.copyHead,
      "linkIncoming": _vm.linkIncoming
    }
  }), _c('pagination', {
    attrs: {
      "total": _vm.items_total,
      "table_name": "incoming"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }