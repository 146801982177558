<template>
  <div class="egais-info-modal">
    <b-modal
      id="egais-confirm-modal"
      centered
      hide-footer
    >
      <template #modal-header>
        <div class="header">
          <div class="title">Подтверждение отмены ТТН</div>
        </div>
      </template>
      Вы действительно хотите отменить ТТН?
      <div class="d-flex mt-3 mb-3">
        <e-button
          variant="primary"
          class="mr-3"
          @click="cancelTTN"
        >
          Продолжить
        </e-button>
        <e-button
          variant="outline-primary"
          @click="hideConfirm"
        >
          Отмена
        </e-button>
      </div>
    </b-modal>
    <b-modal
      id="egais-info-modal"
      class="egais-info-modal"
      show
      size="xl"
      centered
      :busy="$apollo.queries.TicketsByDocument.loading"
      hide-footer
    >
      <template #modal-header="{ close }">
        <div class="header">
          <div class="title">События</div>
          <div class="delimiter"></div>
          <div class="title-info">№ {{ head?.number }} ({{ head?.supplierDocument?.id }})</div>
          <div class="delimiter"></div>
          <div class="edo_id">{{ head?.edo?.id }}</div>
          <div class="delimiter"></div>
          <div class="title-info">от {{ convertDate(head?.date) }}</div>
        </div>
        <b-button
          class="close"
          @click="close()"
        >
          <img
            src="/img/icons/modal_close.svg"
            alt=""
          />
        </b-button>
      </template>
      <div class="d-flex mb-3">
        <e-button
          v-if="['draft', 'awaiting_reject'].includes(head?.edo?.status)"
          variant="warning"
          class="mr-3"
          :loading="loading"
          :disabled="loading"
          @click="showConfirm"
        >
          Отменить ТТН
        </e-button>
        <e-button
          v-if="['awaiting_repeal', 'egais_confirmed', 'awaitng_egais_confirm'].includes(head?.edo?.status)"
          variant="primary"
          class="mr-3"
          :loading="loading"
          :disabled="loading"
          @click="requestWB"
        >
          Отменить акт подтверждения
        </e-button>
        <e-button
          v-if="['draft', 'awaitng_egais_confirm'].includes(head?.edo?.status)"
          variant="primary"
          :loading="loading"
          :disabled="loading"
          @click="acceptTTN"
        >
          Подтвердить ТТН</e-button
        >
      </div>
      <div class="body">
        <resizable-table
          table_name="egais-info"
          :items="items"
          :default_fields="fields"
          :resizable="false"
          empty_text="Нет событий"
        >
          <template #body_number="data">
            {{ convertDate(data.item.date) }}
          </template>
          <template #body_type="data">
            <div
              v-b-tooltip.hover
              :title="type_mapping[data.item.DocType]"
            >
              {{ type_mapping[data.item.DocType] }}
            </div>
          </template>
          <template #body_status="data">
            <span
              class="edo-status"
              :style="getEgaisStyle(data.item)"
            >
              {{ getEgaisText(data.item) }}
            </span>
          </template>
          <template #body_message="data">
            <div
              v-b-tooltip.hover
              :title="data.item?.OperationResult?.OperationComment || data.item?.Result?.Comments"
            >
              {{ data.item?.OperationResult?.OperationComment || data.item?.Result?.Comments }}
            </div>
          </template>
          <template #body_date="data">
            {{ convertDate(data.item?.OperationResult?.OperationDate || data.item?.Result?.ConclusionDate) }}
          </template>
        </resizable-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import { IncomingHeadModel } from '@/models/incoming-head.model'
  import formatDate from '@/utils/formatDate'
  import ResizableTable from '@/components/ResizableTable'

  export default {
    name: 'EgaisInfoModal',
    components: {
      ResizableTable
    },
    props: {
      head: { type: IncomingHeadModel }
    },
    apollo: {
      TicketsByDocument: {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        query: require('./gql/TicketsByDocument.graphql'),
        variables() {
          return {
            input: {
              id: this.head.id
            }
          }
        },
        result({ data }) {
          this.items = data?.TicketsByDocument || []
        },
        skip() {
          return !this.head?.id
        }
      }
    },
    data() {
      return {
        items: [],
        loading: false,
        type_mapping: {
          WAYBILL: 'Акт подтверждения',
          WayBillAct_v4: 'Акт разногласий для ТоварноТранспортной Накладной',
          RequestRepealWB: 'Запрос на отмену проведения ТТН от грузополучателя',
          ConfirmRepealWB: 'Подтверждение запроса на отмену проведения ТТН (оформляет грузоотправитель)'
        },
        edo_status: {
          Rejected: {
            background: '#FFEBEE',
            color: '#E53835',
            text: 'Не принят ЕГАИС'
          },
          Accepted: {
            background: '#EBF9F5',
            color: '#00CB91',
            text: 'Принят ЕГАИС'
          }
        },
        fields: [
          {
            key: 'number',
            label: '№/Дата',
            checked: true,
            width: 180
          },
          {
            key: 'type',
            label: 'Вид документа',
            checked: true,
            width: 250
          },
          {
            key: 'status',
            label: 'Статус',
            checked: true,
            width: 170
          },
          {
            key: 'message',
            label: 'Примечание',
            checked: true,
            width: 320
          },
          {
            key: 'date',
            label: 'Дата обработки',
            checked: true,
            width: 140
          }
        ]
      }
    },
    methods: {
      getEgaisText(item) {
        if (!item.TransportId) return 'Отправка в УТМ'
        if (item.TransportId && !item.DocId) return 'Ожидание УТМ'
        return this.edo_status[item?.OperationResult?.OperationResult || item?.Result?.Conclusion]?.text
      },
      getEgaisStyle(item) {
        if (!item?.OperationResult?.OperationResult && !item?.Result?.Conclusion)
          return {
            background: '#FFFAE7',
            color: '#D4A600'
          }
        return {
          color: this.edo_status[item?.OperationResult?.OperationResult || item?.Result?.Conclusion]?.color,
          background: this.edo_status[item?.OperationResult?.OperationResult || item?.Result?.Conclusion]?.background
        }
      },
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'datetime')
      },
      hideConfirm() {
        this.$bvModal.hide('egais-confirm-modal')
      },
      showConfirm() {
        this.$bvModal.show('egais-confirm-modal')
      },
      async cancelTTN() {
        this.loading = true
        try {
          await this.$apollo.mutate({
            mutation: require('./gql/CancelTtn.graphql'),
            variables: {
              input: {
                id: this.head.id,
                status: 'Rejected'
              }
            }
          })
          this.$noty.show('Успешно!')
          await this.$apollo.queries.TicketsByDocument.refetch()
        } catch (e) {
          this.$noty.show('Ошибка!')
        }
        this.loading = false
      },
      async requestWB() {
        this.loading = true
        try {
          await this.$apollo.mutate({
            mutation: require('./gql/EgaisRepealWb.graphql'),
            variables: {
              input: {
                id: this.head.id
              }
            }
          })
          this.$noty.show('Успешно!')
          await this.$apollo.queries.TicketsByDocument.refetch()
        } catch (e) {
          this.$noty.show('Ошибка!')
        }

        this.loading = false
      },
      async acceptTTN() {
        this.loading = true
        try {
          await this.$apollo.mutate({
            mutation: require('./gql/CancelTtn.graphql'),
            variables: {
              input: {
                id: this.head.id,
                status: 'Accepted'
              }
            }
          })
          this.$noty.show('Успешно!')
          await this.$apollo.queries.TicketsByDocument.refetch()
        } catch (e) {
          this.$noty.show('Ошибка!')
        }
        this.loading = false
      }
    }
  }
</script>

<style scoped lang="scss">
  .header {
    display: flex;
    align-items: center;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #313131;
    }
    .title-info {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }
    .delimiter {
      width: 1px;
      height: 12px;
      background: #bcbcbc;
      border-radius: 14px;
      margin: 0px 8px 0px 8px;
    }
    .edo_id {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .edo-status {
    padding: 4px 12px;
    border-radius: 3px;
  }

  ::v-deep() {
    .modal-body {
      overflow-y: hidden !important;
    }
    .modal-dialog,
    .modal-content {
      /* 80% of window height */
      height: 80%;
    }
    .card {
      height: calc(100% - 60px) !important;
    }

    .alco-declarations-products-table {
      &::-webkit-scrollbar-thumb {
        background: #bebebe;
        border-radius: 10px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    }

    .modal-body {
      /* 100% = dialog height, 120px = header + footer */
      height: 100%;
      overflow-y: scroll;
    }
    .badge {
      padding: 4px 12px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      background: #efefef;
      color: #888888;

      &.success {
        background: #ebf9f5;
        color: #00cb91;
      }
    }
  }
</style>
