import { BranchModel } from '@/models/branch.model'
import { OperationModel } from '@/models/operation.model'
import { StorageModel } from '@/models/storage.model'
import { SupplierModel } from '@/models/supplier.model'
import { SupplierDocumentModel } from '@/models/supplier-document.model'
import { IncomingSpecificationPaginationModel } from '@/models/incoming-spec-pagination.model'
import { UserModel } from '@/models/user.model'
import { IncomingSpecificationModel } from '@/models/incoming-spec.model'

export class IncomingHeadModel {
  constructor(request = {}) {
    this.branch = new BranchModel(request?.branch)
    this.comment = request?.comment
    this.date = request?.date ? new Date(request?.date) : new Date()
    this.fixed = request?.fixed
    this.id = request?.id
    this.name = request?.name
    this.number = request?.number
    this.operation = new OperationModel(request?.operation)
    this.owner = new UserModel(request?.owner)
    this.paginationSpecifications = new IncomingSpecificationPaginationModel(request?.paginationSpecifications)
    this.retail_sum = request?.retail_sum
    this.storage = new StorageModel(request?.storage)
    this.sum = request?.sum
    this.supplier = new SupplierModel(request?.supplier)
    this.supplierDocument = new SupplierDocumentModel(request?.supplierDocument)
  }

  setSpec(specIndex, incomingSpecificationElement) {
    if (specIndex < 0) return
    const specification = this.paginationSpecifications.specification?.map((el) => new IncomingSpecificationModel(el))
    this.paginationSpecifications.sumTotal -= specification[specIndex].sum
    this.paginationSpecifications.factTotal -= specification[specIndex].delta
    specification[specIndex] = new IncomingSpecificationModel(incomingSpecificationElement)
    this.paginationSpecifications.sumTotal += specification[specIndex].sum
    this.paginationSpecifications.factTotal += specification[specIndex].delta

    this.paginationSpecifications = new IncomingSpecificationPaginationModel({
      ...this.paginationSpecifications,
      specification: specification
    })
  }

  setStorage = (val) => {
    this.storage = val
  }

  setSupplier = (val) => {
    this.supplier = val
  }

  setOperation = (val) => {
    this.operation = val
  }

  setDate = (val) => {
    this.date = new Date(val)
  }

  setSupplierDocumentId = (val) => {
    this.supplierDocument.id = val
  }
  setSupplierDocumentDate = (val) => {
    this.supplierDocument.date = new Date(val)
  }

  setComment = (val) => {
    this.comment = val
  }
  get input() {
    return {
      branch: this.branch?.id,
      comment: this.comment,
      date: this.date,
      id: this.id,
      name: this.name,
      storage: this.storage?.id,
      supplier: this.supplier.id,
      supplierDocument: this.supplierDocument,
      operation: this.operation
    }
  }
}
