<template>
  <b-modal
    id="excel-import"
    body-class="p-0"
    size="xl"
    hide-footer
    hide-header
    centered
    @hide="clear"
  >
    <h3 class="ml-3">Импорт из Excel</h3>
    <div class="d-flex w-100">
      <b-col cols="2">
        <label for="sheet-input">Строки</label>
        <b-form-input
          id="rows-input"
          v-model="rows"
          class="w-100"
          placeholder="1-5"
          :class="{ error: is_range_error }"
        />
      </b-col>
      <b-col cols="3">
        <label>Поставщик</label>
        <i-select
          v-model="supplier"
          filterable
          clearable
          :class="{
            error: supplier_error
          }"
        >
          <i-option
            v-for="option of suppliers"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </i-option>
        </i-select>
      </b-col>
    </div>
    <b-card
      no-body
      class="m-3 p-0"
      style="height: 65vh"
    >
      <xlsx-read
        :file="file"
        @parsed="getSheets"
      >
        <xlsx-json
          :sheet="selectedSheet"
          :options="{ header: 'A' }"
          @parsed="setSheets"
        />
      </xlsx-read>
      <div class="w-100 overflow-auto h-100">
        <div
          :style="{ width: fields.length * 200 + 'px' }"
          class="h-100 d-flex flex-column"
        >
          <div class="d-flex w-100">
            <div
              v-for="(field, index) in fields"
              :key="index"
              class="virtual-field"
            >
              <div>
                <i-select
                  v-model="selectedValue[index]"
                  filterable
                  clearable
                  @on-change="setColumn(index)"
                >
                  <i-option
                    v-for="option of columns"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </i-option>
                </i-select>
              </div>
              <div class="label">
                {{ field.label }}
              </div>
            </div>
          </div>
          <virtual-list
            ref="virtual"
            style="overflow-y: auto"
            :data-key="'id'"
            :wrap-style="{ width: '100%', overflow: 'hidden' }"
            :data-sources="parsed"
            :data-component="ItemRow"
          />
          <div
            style="position: absolute; right: 10px; bottom: 65px; transform: rotate(180deg)"
            class="scroll-control"
            @click="scroll('top')"
          >
            <img src="/img/icons/scroll-controll.svg" />
          </div>
          <div
            style="position: absolute; right: 10px; bottom: 10px"
            class="scroll-control"
            @click="scroll('bottom')"
          >
            <img src="/img/icons/scroll-controll.svg" />
          </div>
        </div>
      </div>
      <!--      <b-table :items="parsed" :busy="isBusy" :fields="fields">-->
      <!--        <template #thead-top>-->
      <!--          <tr>-->
      <!--            <th-->
      <!--              class="p-0"-->
      <!--              style="border: none !important"-->
      <!--              v-for="(field, index) of fields"-->
      <!--              :key="field.key"-->
      <!--            >-->
      <!--              <i-select-->
      <!--                filterable-->
      <!--                clearable-->
      <!--                @on-change="setColumn(index)"-->
      <!--                v-model="selectedValue[index]"-->
      <!--              >-->
      <!--                <i-option-->
      <!--                  v-for="option of columns"-->
      <!--                  :value="option.value"-->
      <!--                  :key="option.value"-->
      <!--                  >{{ option.label }}-->
      <!--                </i-option>-->
      <!--              </i-select>-->
      <!--            </th>-->
      <!--          </tr>-->
      <!--        </template>-->
      <!--        <template v-slot:cell(№)="{ index }">-->
      <!--          {{ index + 1 }}-->
      <!--        </template>-->

      <!--        <template #table-busy>-->
      <!--          <div class="text-center">-->
      <!--            <b-spinner variant="primary" label="Загрузка..." />-->
      <!--            <div><strong>Загрузка...</strong></div>-->
      <!--          </div>-->
      <!--        </template>-->

      <!--        <template #cell()="{ value }">-->
      <!--          {{ formatCells(value) }}-->
      <!--        </template>-->
      <!--      </b-table>-->
    </b-card>

    <b-card
      no-body
      class="p-0 m-3"
    >
      <b-row
        cols="8"
        class="p-0 m-0"
      >
        <b-col
          v-for="sheet of sheets"
          :key="sheet"
          cols="1"
          class="sheet-label"
          :style="selectedSheet === sheet ? { background: '#EFEFEF' } : { background: '#FFFFFF' }"
        >
          <div style="display: flex; flex-direction: row; align-items: center">
            <span
              class="cursor"
              @click="selectedSheet = sheet"
              >{{ sheet }}</span
            >
            <div
              class="ml-2 cursor"
              style="
                display: flex;
                border-radius: 90px;

                width: 16px;
                height: 16px;
                align-items: center;
                justify-content: center;
              "
              :style="selectedSheet === sheet ? { background: '#FFFFFF' } : { background: '#f2f3f6' }"
            >
              <img
                src="/img/icons/icon_cancel.svg"
                style="height: 8px; width: 8px"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <div class="m-3">
      <b-button
        variant="primary"
        @click="imports"
      >
        Импортировать
      </b-button>
      <b-button
        variant="outline-primary"
        class="ml-2"
        @click="closeModal"
      >
        Отмена
      </b-button>
    </div>
  </b-modal>
</template>

<script>
  import { XlsxRead, XlsxJson } from 'vue-xlsx'
  import { mapGetters } from 'vuex'
  import ItemRow from '@/views/operational-processes/components/excel/ItemRow.vue'

  export default {
    name: 'ExcelImport',
    components: {
      XlsxRead,
      XlsxJson
    },
    data() {
      return {
        parsed: [],
        sheets: [],
        fields: [],
        suppliers: [],
        measurementList: [],
        supplier: null,
        supplier_error: false,
        tableMapping: [],
        selectedSheet: null,
        is_range_error: false,
        is_column_error: false,
        isBusy: false,
        dochead: null,
        columns: [
          { label: 'Внешний код поставщика', value: 'ext_articul' },
          { label: 'Единица измерения', value: 'measurement' },
          { label: 'Название', value: 'name' },
          { label: 'Сумма с НДС', value: 'sum' },
          { label: 'Количество', value: 'delta' },
          { label: 'Штрихкод', value: 'barcode' },
          { label: 'Розничная цена', value: 'retailPrice' },
          { label: 'Закупочная цена', value: 'price' },
          { label: 'Путь', value: 'category' },
          { label: 'Код на весах', value: 'plu' }
        ],
        rows: '',
        selectedValue: [],
        default: null,
        requiredFields: ['delta', 'name']
      }
    },
    apollo: {
      ProductDefault: {
        query: require('../../../products/gql/getProductDefault.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.default = data.ProductDefault
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Suppliers: {
        query: require('../../../products/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.suppliers = [{ id: null, name: 'Все' }, ...(data.Suppliers ?? [])]
        }
      },
      Measurements: {
        query: require('../../../products/gql/getMeasurements.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.measurementList = data.Measurements
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    props: {
      file: {
        type: File,
        default: null
      }
    },
    methods: {
      scroll(method) {
        if (method === 'bottom') {
          this.$refs.virtual.scrollToBottom()
        } else if (method === 'top') {
          this.$refs.virtual.scrollToOffset()
        }
      },
      formatCells(data) {
        if (typeof data === 'number') return +data?.toFixed(2) || 0
        return data
      },
      setSheets(e) {
        const keys = Object.keys(e[0])
        this.parsed = e.map((el, index) => {
          const obj = { id: index + 1 }
          for (let key of keys) {
            obj[key] = el[key]
          }
          return obj
        })
        this.fields = Object.keys(this.parsed[0]).map((el) => {
          if (el === 'id') return { label: '№', key: 'id', thClass: 'p-1 text-left' }
          return { label: el, key: el, thClass: 'p-1 text-left' }
        })
      },
      clear() {
        this.parsed = null
        this.sheets = []
        this.fields = []
        this.tableMapping = []
        this.selectedSheet = null
        this.is_range_error = false
        this.is_column_error = false
        this.isBusy = false
        this.dochead = null
      },
      closeModal() {
        this.$bvModal.hide('excel-import')
      },
      getSheets(e) {
        this.sheets.push(...e.SheetNames)
        this.selectedSheet = this.sheets[0]
      },
      async imports() {
        let prods = []
        let fields = this.tableMapping.map((el) => el.value)
        this.supplier_error = false
        if (!fields.some((el) => el === 'price' || el === 'retailPrice' || el === 'sum')) {
          this.$noty.show('Не выбрана цена')
          return
        }
        if (
          !this.requiredFields.every((element) => {
            return fields.includes(element)
          })
        ) {
          this.$noty.show(
            `Не выбраны обязательные поля ${this.requiredFields
              .filter((el) => !fields.includes(el))
              .map((el) => {
                return el === 'delta' ? 'количество ' : el === 'sum' ? 'сумма ' : 'название'
              })}`
          )
          return
        }
        let prods_to_import = []
        if (this.formattedRows(this.rows) || this.formattedRows(this.rows).from > this.formattedRows(this.rows).to) {
          this.is_range_error = false
          for (let map of this.tableMapping.filter(Boolean)) {
            this.parsed = this.parsed.map((el) => {
              return {
                ...el,
                [map.value]: el[map.label]
              }
            })
          }
          for (let i = this.formattedRows(this.rows).from - 1; i < this.formattedRows(this.rows).to; i++) {
            prods_to_import.push(this.parsed[i])
          }
          this.isBusy = true
          let l = 0
          while (l < prods_to_import.length) {
            prods.push(
              ...((
                await this.$apollo
                  .mutate({
                    mutation: require('./gql/ProductImport.graphql'),
                    variables: {
                      product: prods_to_import.slice(l, l + 100).map((el) => {
                        return {
                          name: String(el.name),
                          measurement:
                            this.measurementList.find((el2) =>
                              el2.name?.toLowerCase()?.includes(el.measurement?.toLowerCase())
                            )?.id ?? this.default.measurement.id,
                          branch: this.currentBranch.id,
                          supplier: this.supplier ?? null,
                          ext_articul: el.ext_articul ? String(el.ext_articul) : null,
                          barcode: el.barcode ? String(el.barcode) : null,
                          category: el.category ?? null,
                          plu: el.plu?.toString() ?? null
                        }
                      })
                    }
                  })
                  .catch(() => {
                    this.$noty.show('Ошибка импорта')
                    this.isBusy = false
                  })
              ).data.ImportProduct?.productImports ?? [])
            )
            l += 100
          }
          let res = await this.$apollo.mutate({
            mutation: require('../../gql/CreateIncomingDocument.gql'),
            variables: {
              operation: 'income',
              branch: this.currentBranch.id,
              supplier: this.supplier
            }
          })
          this.dochead = res.data.CreateIncomingDocument?.id ?? null
          if (this.dochead) {
            let iter = 0
            while (iter <= prods_to_import.length) {
              await this.$apollo.mutate({
                mutation: require('../../gql/CreateIncomingSpecification.gql'),
                variables: {
                  document_head: this.dochead,
                  productChange: Array.isArray(prods_to_import)
                    ? prods_to_import.slice(iter, iter + 100).map((el) => {
                        let imported = prods?.find((el2) => el2.req_name === el.name)
                        return {
                          delta: Number(el.delta ?? 1) ?? 1,
                          sum: el.price ? +(el.price * el.delta)?.toFixed(2) : +(el.sum ?? 1) || 1,
                          price:
                            +Number(el.price)?.toFixed(2) ||
                            (Number(el.sum ?? 1) / Number(el.delta ?? 1) ?? 0) ||
                            imported.product.price ||
                            0,
                          retailPrice: el.retailPrice
                            ? +el.retailPrice?.toFixed(2)
                            : imported.product.markup
                            ? (Number(el.sum ?? 1) / Number(el.delta ?? 1)) *
                              (1 + (imported.product?.markup ?? 0) / 100 ?? 1)
                            : imported.product.retailPrice ||
                              Number(el.sum ?? 1) / Number(el.delta ?? 1) ||
                              +el.price?.toFixed(2) ||
                              0,
                          product: imported.product?.id,
                          nds: imported.product.taxRate?.id ?? this.default.taxRate.id
                        }
                      })
                    : [
                        prods_to_import.map((el) => {
                          let imported = prods?.find((el2) => String(el2.req_name) === String(el.name))
                          return {
                            delta: Number(el.delta ?? 1) ?? 1,
                            sum: Number(el.sum ?? 1) ?? 1,
                            price: Number(el.sum ?? 1) / Number(el.delta ?? 1) ?? 1,
                            retailPrice:
                              (Number(el.sum ?? 1) / Number(el.delta ?? 1)) *
                                (1 + (imported.product?.markup ?? 0) / 100 ?? 1) ?? 1,
                            product: imported?.product?.id,
                            nds: imported.product?.taxRate?.id ?? this.default.taxRate.id
                          }
                        })
                      ]
                }
              })
              iter += 100
            }
            await this.$router.push({
              name: `operation-process.overhead.incoming.document.edit`,
              params: { id: this.dochead }
            })
            this.isBusy = false
          }
        } else {
          this.is_range_error = true
          this.$noty.show('Неверно введен диапазон строк!')
        }
      },
      formattedRows(a) {
        if (a.length && a?.indexOf('-') > -1 && a?.indexOf('-') < a?.length - 1)
          return {
            from: a.slice(0, a.indexOf('-')),
            to: a.slice(a.indexOf('-') + 1, a.length)
          }
        else return false
      },
      setColumn(index) {
        this.tableMapping[index] = {
          value: this.selectedValue[index],
          label: this.fields[index].key
        }
      }
    },
    computed: {
      ItemRow() {
        return ItemRow
      },
      ...mapGetters({ currentBranch: 'settings/getCurrentBranch' })
    }
  }
</script>

<style scoped lang="scss">
  .scroll-control {
    background: white;
    width: 48px;
    height: 48px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sheet-label {
    align-items: center;
    padding: 6px 12px;
    border-right: 1px solid #e1e1e1;
  }
  .scroller {
    height: 90px;
  }
  .virtual-field {
    width: 200px;
    .label {
      color: #888;
      padding: 3px 16px;
    }
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }
</style>
