var render = function render(){
  var _vm$head5, _vm$head5$edo, _vm$head6, _vm$head6$edo, _vm$head7, _vm$head7$edo;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "egais-info-modal"
  }, [_c('b-modal', {
    attrs: {
      "id": "egais-confirm-modal",
      "centered": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("Подтверждение отмены ТТН")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" Вы действительно хотите отменить ТТН? "), _c('div', {
    staticClass: "d-flex mt-3 mb-3"
  }, [_c('e-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.cancelTTN
    }
  }, [_vm._v(" Продолжить ")]), _c('e-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideConfirm
    }
  }, [_vm._v(" Отмена ")])], 1)]), _c('b-modal', {
    staticClass: "egais-info-modal",
    attrs: {
      "id": "egais-info-modal",
      "show": "",
      "size": "xl",
      "centered": "",
      "busy": _vm.$apollo.queries.TicketsByDocument.loading,
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var _vm$head, _vm$head2, _vm$head2$supplierDoc, _vm$head3, _vm$head3$edo, _vm$head4;

        var close = _ref.close;
        return [_c('div', {
          staticClass: "header"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("События")]), _c('div', {
          staticClass: "delimiter"
        }), _c('div', {
          staticClass: "title-info"
        }, [_vm._v("№ " + _vm._s((_vm$head = _vm.head) === null || _vm$head === void 0 ? void 0 : _vm$head.number) + " (" + _vm._s((_vm$head2 = _vm.head) === null || _vm$head2 === void 0 ? void 0 : (_vm$head2$supplierDoc = _vm$head2.supplierDocument) === null || _vm$head2$supplierDoc === void 0 ? void 0 : _vm$head2$supplierDoc.id) + ")")]), _c('div', {
          staticClass: "delimiter"
        }), _c('div', {
          staticClass: "edo_id"
        }, [_vm._v(_vm._s((_vm$head3 = _vm.head) === null || _vm$head3 === void 0 ? void 0 : (_vm$head3$edo = _vm$head3.edo) === null || _vm$head3$edo === void 0 ? void 0 : _vm$head3$edo.id))]), _c('div', {
          staticClass: "delimiter"
        }), _c('div', {
          staticClass: "title-info"
        }, [_vm._v("от " + _vm._s(_vm.convertDate((_vm$head4 = _vm.head) === null || _vm$head4 === void 0 ? void 0 : _vm$head4.date)))])]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }])
  }, [_c('div', {
    staticClass: "d-flex mb-3"
  }, [['draft', 'awaiting_reject'].includes((_vm$head5 = _vm.head) === null || _vm$head5 === void 0 ? void 0 : (_vm$head5$edo = _vm$head5.edo) === null || _vm$head5$edo === void 0 ? void 0 : _vm$head5$edo.status) ? _c('e-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "warning",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.showConfirm
    }
  }, [_vm._v(" Отменить ТТН ")]) : _vm._e(), ['awaiting_repeal', 'egais_confirmed', 'awaitng_egais_confirm'].includes((_vm$head6 = _vm.head) === null || _vm$head6 === void 0 ? void 0 : (_vm$head6$edo = _vm$head6.edo) === null || _vm$head6$edo === void 0 ? void 0 : _vm$head6$edo.status) ? _c('e-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.requestWB
    }
  }, [_vm._v(" Отменить акт подтверждения ")]) : _vm._e(), ['draft', 'awaitng_egais_confirm'].includes((_vm$head7 = _vm.head) === null || _vm$head7 === void 0 ? void 0 : (_vm$head7$edo = _vm$head7.edo) === null || _vm$head7$edo === void 0 ? void 0 : _vm$head7$edo.status) ? _c('e-button', {
    attrs: {
      "variant": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.acceptTTN
    }
  }, [_vm._v(" Подтвердить ТТН")]) : _vm._e()], 1), _c('div', {
    staticClass: "body"
  }, [_c('resizable-table', {
    attrs: {
      "table_name": "egais-info",
      "items": _vm.items,
      "default_fields": _vm.fields,
      "resizable": false,
      "empty_text": "Нет событий"
    },
    scopedSlots: _vm._u([{
      key: "body_number",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.convertDate(data.item.date)) + " ")];
      }
    }, {
      key: "body_type",
      fn: function (data) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": _vm.type_mapping[data.item.DocType]
          }
        }, [_vm._v(" " + _vm._s(_vm.type_mapping[data.item.DocType]) + " ")])];
      }
    }, {
      key: "body_status",
      fn: function (data) {
        return [_c('span', {
          staticClass: "edo-status",
          style: _vm.getEgaisStyle(data.item)
        }, [_vm._v(" " + _vm._s(_vm.getEgaisText(data.item)) + " ")])];
      }
    }, {
      key: "body_message",
      fn: function (data) {
        var _data$item, _data$item$OperationR, _data$item2, _data$item2$Result, _data$item3, _data$item3$Operation, _data$item4, _data$item4$Result;

        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": ((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$OperationR = _data$item.OperationResult) === null || _data$item$OperationR === void 0 ? void 0 : _data$item$OperationR.OperationComment) || ((_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$Result = _data$item2.Result) === null || _data$item2$Result === void 0 ? void 0 : _data$item2$Result.Comments)
          }
        }, [_vm._v(" " + _vm._s(((_data$item3 = data.item) === null || _data$item3 === void 0 ? void 0 : (_data$item3$Operation = _data$item3.OperationResult) === null || _data$item3$Operation === void 0 ? void 0 : _data$item3$Operation.OperationComment) || ((_data$item4 = data.item) === null || _data$item4 === void 0 ? void 0 : (_data$item4$Result = _data$item4.Result) === null || _data$item4$Result === void 0 ? void 0 : _data$item4$Result.Comments)) + " ")])];
      }
    }, {
      key: "body_date",
      fn: function (data) {
        var _data$item5, _data$item5$Operation, _data$item6, _data$item6$Result;

        return [_vm._v(" " + _vm._s(_vm.convertDate(((_data$item5 = data.item) === null || _data$item5 === void 0 ? void 0 : (_data$item5$Operation = _data$item5.OperationResult) === null || _data$item5$Operation === void 0 ? void 0 : _data$item5$Operation.OperationDate) || ((_data$item6 = data.item) === null || _data$item6 === void 0 ? void 0 : (_data$item6$Result = _data$item6.Result) === null || _data$item6$Result === void 0 ? void 0 : _data$item6$Result.ConclusionDate))) + " ")];
      }
    }])
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }