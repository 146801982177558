var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items > 0 ? _c('div', {
    staticClass: "b-table__navbar"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": "/img/icons/table/icon_checked.svg",
      "alt": ""
    },
    on: {
      "click": _vm.clear_selected
    }
  }), _vm._v(" " + _vm._s(_vm.items) + " " + _vm._s(_vm.item_name) + " "), _c('div', {
    staticClass: "b-btns"
  }, [!_vm.edo ? _c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.removeItem
    }
  }, [_vm._v(" Удалить ")]) : _vm._e(), _c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.printPriceTags
    }
  }, [_vm._v(" Печать ")]), _c('b-dropdown', {
    staticClass: "navbar-dropdown",
    attrs: {
      "dropup": "",
      "no-caret": "",
      "variant": "dark"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm._v("Действия")];
      },
      proxy: true
    }], null, false, 4277375889)
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.PriceUp
    }
  }, [_vm._v(" " + _vm._s(_vm.operation !== 'commission' ? 'Наценить' : 'Назначить % комиссии'))]), _c('b-dropdown-item', {
    on: {
      "click": _vm.CostsAdd
    }
  }, [_vm._v(" Назначить издержки")]), _vm.items === 1 ? _c('b-dropdown-item', {
    on: {
      "click": _vm.editProduct
    }
  }, [_vm._v(" Редактировать товар ")]) : _vm._e(), _vm.items === 1 ? _c('b-dropdown-item', {
    on: {
      "click": _vm.specMove
    }
  }, [_vm._v(" Изменить порядок ")]) : _vm._e(), _c('b-dropdown-item', {
    on: {
      "click": _vm.openAddDocumentInContractModal
    }
  }, [_c('div', [_vm._v("Внести в контракт")])])], 1), _vm.items === 1 ? _c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.showMovement
    }
  }, [_vm._v(" Показать товародвижение ")]) : _vm._e()], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }