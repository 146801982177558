<template>
  <div
    v-if="items > 0"
    class="b-table__navbar"
  >
    <img
      src="/img/icons/table/icon_checked.svg"
      alt=""
      class="cursor"
      @click="clear_selected"
    />
    {{ items }}
    {{ item_name }}
    <div class="b-btns">
      <b-button
        v-if="!edo"
        variant="dark"
        @click="removeItem"
      >
        Удалить
      </b-button>
      <b-button
        variant="dark"
        @click="printPriceTags"
      >
        Печать
      </b-button>
      <b-dropdown
        dropup
        no-caret
        class="navbar-dropdown"
        variant="dark"
      >
        <template #button-content>Действия</template>
        <b-dropdown-item @click="PriceUp">
          {{ operation !== 'commission' ? 'Наценить' : 'Назначить % комиссии' }}</b-dropdown-item
        >
        <b-dropdown-item @click="CostsAdd"> Назначить издержки</b-dropdown-item>
        <b-dropdown-item
          v-if="items === 1"
          @click="editProduct"
        >
          Редактировать товар
        </b-dropdown-item>
        <b-dropdown-item
          v-if="items === 1"
          @click="specMove"
        >
          Изменить порядок
        </b-dropdown-item>
        <b-dropdown-item @click="openAddDocumentInContractModal">
          <div>Внести в контракт</div>
        </b-dropdown-item>
      </b-dropdown>

      <b-button
        v-if="items === 1"
        variant="dark"
        @click="showMovement"
      >
        Показать товародвижение
      </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: Number,
        default: () => 0
      },
      clear_selected: {
        type: Function,
        default: () => {}
      },
      edo: {
        type: Boolean,
        default: () => false
      },
      operation: String
    },

    computed: {
      item_name() {
        if ((this.items % 100) % 10 === 1) return 'Товар'
        if ((this.items % 100) % 10 > 1 && (this.items % 100) % 10 < 5) return 'Товара'
        return 'Товаров'
      }
    },

    methods: {
      removeItem() {
        this.$emit('remove_items')
      },

      openAddDocumentInContractModal() {
        this.$emit('open_add_product_in_contract_modal')
      },

      editProduct() {
        this.$emit('edit_product')
      },

      showMovement() {
        this.$bvModal.show('product-movement')
      },
      specMove() {
        this.$bvModal.show('modal-spec-move')
      },

      printPriceTags() {
        this.$emit('print_price_tags')
      },

      PriceUp() {
        this.$bvModal.show('modal-price-up')
      },
      CostsAdd() {
        this.$bvModal.show('modal-costs-add')
      }
    }
  }
</script>
