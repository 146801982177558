<template>
  <section id="operproccess__coming">
    <excel-import :file="file" />
    <modal-print-price-tags :products="printProducts" />
    <egais-info-modal :head="currentHead" />
    <!-- <div class="content__header">{{ title }}</div> -->
    <div class="b-table__wrapper">
      <documents-header
        filters
        page_type="incoming"
        :set_file="setFile"
        :choose_files="chooseFiles"
        @date_range="updateDateRange"
        @update_filters="update_filters"
      />
      <div class="b-table__content">
        <documents-table-filter
          ref="doc_table_filter"
          :total="total"
          :selected_sum="selected_sum"
          table_name="incoming"
          @handler_pagination="handlerSearch"
          @clear="clearSearchStr"
        />
        <incoming-documents-table
          ref="incoming-documents-table"
          :is_clear_selected="isClearSelected"
          :fields="fields"
          :items_total="items_total"
          :items="document_list"
          :table_is_busy="tableIsBusy"
          page_type="incoming"
          @select="setSelected"
          @showEgaisModal="showEgaisModal"
          @getLinkedHeads="getLinkedHeads"
          @removeLincked="removeLincked"
        />
      </div>
      <table-navbar
        :items="getSelectedArr"
        hide_print
        items_name="documents"
        doc_type="incoming"
        :cancel_ttn="has_egais"
        :clear_selected="clearSelected"
        @print_price_tags="printPriceTags"
        @remove_items="remove_items"
        @cancelTtn="cancel_ttn"
        @copyHead="copyHead"
        @linkIncoming="linkIncoming"
      />
      <pagination
        :total="items_total"
        table_name="incoming"
        @change_pagination="handlerPagination"
      />
    </div>
  </section>
</template>

<script>
  import DocumentsHeader from '../../components/DocumentsHeader'
  import DocumentsTableFilter from '../../components/DocumentsTableFilter'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
  import ExcelImport from '@/views/operational-processes/components/excel/ExcelImport'
  import IncomingDocumentsTable from '@/views/operational-processes/components/incoming/IncomingDocumentsTable'
  import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'
  import { getNewToken } from '@/utils/crptech/crptech_token'
  import { http } from '@/http/http'
  import { ProductModel } from '@/models/product.model'
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags'
  import EgaisInfoModal from '@/views/core/modal/EgaisInfoModal.vue'
  import IncomingNavbar from '@/views/operational-processes/components/incoming/IncomingNavbar.vue'

  export default {
    components: {
      IncomingNavbar,
      EgaisInfoModal,
      ModalPrintPriceTags,
      IncomingDocumentsTable,
      ExcelImport,
      DocumentsHeader,
      DocumentsTableFilter,
      Pagination,
      TableNavbar
    },

    apollo: {
      IncomingHeads: {
        query: require('../../gql/getIncomingHeads.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch?.__typename === 'BranchClassifModel' ? null : this.currentBranch.id,
            operation: this.getFilters?.operation.length
              ? this.getFilters.operation.map((item) => item.key)
              : ['income', 'bonus', 'commission'],
            fixed: this.getStatus,
            documentPagination: {
              period: {
                begin: this.data_range[0] ?? null,
                end: this.data_range[1] ?? null
              },
              skip: this.getDocumentsPagination?.['incoming'].skip,
              take: this.getDocumentsPagination?.['incoming'].take,
              search: this.getDocumentsPagination?.['incoming'].search,
              storage: this.getFilters?.storages.map((item) => item.id) || [],
              supplier: this.getFilters?.supplier.map((item) => item.id) || [],
              edo: this.getType,
              owner: this.getFilters?.owners.map((item) => item.id) || []
            },
            group: this.currentBranch?.__typename === 'BranchClassifModel' ? this.currentBranch.id : null
          }
        },
        result({ data }) {
          this.SET_SELECTED([])
          this.items_total = data.IncomingHeads?.total ?? 0
          this.total.nds_sum = data.IncomingHeads?.nds_sum ?? 0
          this.total.pur_sum = data.IncomingHeads?.pur_sum ?? 0
          this.total.ret_sum = data.IncomingHeads?.ret_sum ?? 0
          this.document_list = data.IncomingHeads?.documents?.map((el) => new IncomingHeadModel(el)) ?? []
          this.tableIsBusy = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.tableIsBusy = false
        },
        watchLoading(isLoading) {
          this.tableIsBusy = isLoading
        }
      }
    },

    data: function () {
      return {
        printProducts: [],
        type: null,
        currentHead: null,
        tableIsBusy: true,
        file: null,
        title: this.$route.meta.title,
        filter: null,
        filter_date_range: null,
        isClearSelected: false,
        selected_sum: null,
        document_list: [],
        table_incoming_fields: [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'center',
            thStyle: 'min-width: 48px'
          },
          {
            key: 'edo',
            label: 'Тип',
            class: 'center',
            thStyle: 'min-width: 54px'
          },
          {
            key: 'date',
            label: '№ / Дата',
            thStyle: 'min-width: 131px',
            sortable: true
          },
          {
            key: 'supplier',
            label: 'Поставщик',
            thStyle: 'min-width: 300px',
            sortable: true
          },
          {
            key: 'supplierDocument',
            label: '№ Внеш. / Дата',
            sortable: true,
            thStyle: 'min-width: 168px'
          },
          {
            key: 'sum',
            label: 'Сумма, ₽',
            sortable: true,
            thStyle: 'min-width: 110px'
          },
          {
            key: 'retail_sum',
            label: 'Сумма в розн., ₽',
            thStyle: 'min-width: 141px'
          },
          {
            key: 'operation',
            label: 'Операция',
            class: 'center',
            thStyle: 'min-width: 110px'
          },
          {
            key: 'fixed',
            label: 'Статус',
            thStyle: 'min-width: 118px',
            class: 'center'
          },
          {
            key: 'edo_status',
            label: 'Состояние',
            thStyle: 'min-width: 200px',
            class: 'center'
          },
          {
            key: 'owner',
            label: 'Менеджер',
            class: 'center',
            thStyle: 'min-width: 188px'
          },
          {
            key: 'comment',
            label: 'Комментарий',
            thStyle: 'min-width: 200px'
          },
          {
            key: 'payed_sum',
            label: 'Оплата',
            thStyle: 'min-width: 150'
          }
        ],
        items_total: 0,
        total: {
          ret_sum: 0,
          pur_sum: 0,
          nds_sum: 0
        },
        data_range: [],
        pagination: {
          operation: [],
          storage: [],
          supplier: []
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getFilters: 'incoming/getFilters',
        getDocumentsPagination: 'settings/getPagination',
        getSelectedArr: 'incoming/getSelectedArr',
        getSelectAll: 'incoming/getSelectAll'
      }),
      ...mapState({
        selectedItems: 'incoming/selected'
      }),
      // has_egais() {
      //   return this.document_list.some((el) => el.edo?.type === 'egais' && this.selected.includes(el.id))
      // },
      has_egais() {
        return this.document_list.some((el) => el.edo?.type === 'egais' && this.getSelectedArr.includes(el.id))
      },
      fields() {
        return this.table_incoming_fields
      },
      getStatus() {
        if (this.getFilters.status.length === 1) {
          return this.getFilters.status[0].id === 'fixed'
        }
        return null
      },
      getType() {
        return this.getFilters.types.map((item) => item.id) ?? []
      }
    },
    watch: {
      '$route.path'() {
        this.title = this.$route.meta.title
      }
    },

    async beforeMount() {
      try {
        await getNewToken(this.currentBranch.id)
      } catch (e) {
        console.error(e)
      }
      const tokens = JSON.parse(sessionStorage.getItem(this.currentBranch.id))
      const input = []
      for (const token in tokens) {
        input.push(tokens[token])
      }

      const { data } = await this.$apollo.query({
        query: require('@/gql/queries/downloadXMLDocs.graphql'),
        fetchPolicy: 'no-cache',
        variables: {
          tokens: input
        }
      })
      if (data?.EDODownload?.status) await this.$apollo.queries.IncomingHeads.refetch()
    },

    updated() {
      this.page_type = this.$route.meta.type
    },

    methods: {
      ...mapActions({
        setFilters: 'incoming/setFilters',
        setDocumentsPagination: 'settings/set_pagination'
      }),
      ...mapMutations({
        SET_SELECTED: 'incoming/SET_SELECTED',
        SET_SELECT_ALL: 'incoming/SET_SELECT_ALL'
      }),

      clearSelected() {
        this.SET_SELECTED([])
        this.SET_SELECT_ALL(false)
      },
      async copyHead(items) {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/CopyIncomingHead.graphql'),
            variables: {
              id: items[0]
            }
          })
          this.SET_SELECTED([])
          this.$refs['incoming-documents-table'].selected = []
          this.$noty.show('Документ успешно скопирован')
          await this.$apollo.queries.IncomingHeads.refetch()
        } catch (e) {
          console.log(e)
          this.$noty.error('При дублировании документа произошла ошибка')
        }
      },
      async removeLincked(head) {
        this.$bvModal
          .msgBoxConfirm('Вы действительно хотите отвязать документ?', {
            title: 'Подтвердите действие',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            cancelVariant: 'outline-primary',
            okTitle: 'Продолжить',
            footerClass: 'flex-row-reverse',
            cancelTitle: 'Отмена',
            centered: true
          })
          .then(async (value) => {
            if (value) {
              await this.$apollo.mutate({
                mutation: require('../../gql/UnLinkIncomes.graphql'),
                variables: {
                  input: {
                    head: head.parent_head,
                    source: [head.edo.type]
                  }
                }
              })
              await this.$apollo.queries.IncomingHeads.refetch()
            }
          })
      },
      async getLinkedHeads(head) {
        const { data } = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: require('../../gql/IncomeTabs.graphql'),
          variables: {
            id: head.id
          }
        })
        const index = this.document_list.findIndex((el) => el.id === head.id)
        if (!this.document_list[index].opened) {
          this.document_list.splice(
            index + 1,
            0,
            ...data.IncomeTabs.map(
              (el) =>
                new IncomingHeadModel({
                  ...el,
                  parent_head: head.id,
                  number: '-',
                  supplier: head.supplier,
                  supplierDocument: { id: el.ext_head, date: head.supplierDocument.date }
                })
            )
          )
          this.document_list[index].heads_length = data.IncomeTabs.length
        } else {
          this.document_list.splice(index + 1, this.document_list[index].heads_length) //TODO length
        }

        this.document_list[index].opened = !this.document_list[index].opened
      },
      showEgaisModal(head) {
        this.currentHead = head
        this.currentHead.id = head.id || head.parent_head
        this.$bvModal.show('egais-info-modal')
      },
      async printPriceTags(items) {
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadPriceTagsFromDocuments.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              document_heads: items,
              branch: this.currentBranch?.id,
              product: [],
              isPrintAll: true
            }
          }
        })
        if (data?.ReadPriceTagsFromDocuments) {
          this.printProducts = data?.ReadPriceTagsFromDocuments?.map((el) => {
            return new ProductModel({ ...el?.Product, retailPrice: el?.value ?? el?.Product?.retailPrice })
          })
        }
        if (this.printProducts?.length > 0) this.$bvModal.show('modal-print-tags')
      },
      async update_filters(data) {
        this.pagination.storage = data.storage
        this.pagination.supplier = data.supplier
        this.pagination.operation = data.operation
      },
      async setFile(e) {
        this.file = e.target.files[0]
        if (this.type === 'upd_xml') {
          let fd = new FormData()
          fd.append('file', e.target.files[0])
          const { data } = await http.post('files/import/upd', fd)
          return this.$router.push({
            name: 'operation-process.overhead.incoming.document.edit',
            params: { id: data?.id }
          })
        }
        this.$bvModal.show('excel-import')
      },

      setSelected() {
        if (!this.getSelectedArr.length) this.selected_sum = null
        else {
          let selected_docs = this.document_list.filter((obj) => this.getSelectedArr.some((item) => item === obj.id))
          this.selected_sum = selected_docs.reduce(
            (acc, doc) => {
              return {
                sum: acc.sum + (+doc.sum ?? 0),
                retailSum: acc.retailSum + (+doc?.retail_sum ?? 0),
                nds: (acc.nds ?? 0) + (doc?.nds_sum ?? 0)
              }
            },
            { sum: 0, retailSum: 0, nds: 0 }
          )
        }
      },

      chooseFiles(type) {
        this.type = type
        document.getElementById('fileUpload').click()
      },

      updateDateRange(date) {
        this.data_range = date
        this.$apollo.queries.IncomingHeads.refresh()
      },

      handlerSearch(search) {
        this.tableIsBusy = true
        this.setDocumentsPagination({
          ...this.getDocumentsPagination,
          ['incoming']: {
            ...this.getDocumentsPagination?.['incoming'],
            current_page: 1,
            skip: 0,
            search: search
          }
        })
      },

      handlerPagination({ current_page = null, take = null }) {
        if (current_page) {
          this.setDocumentsPagination({
            ...this.getDocumentsPagination,
            ['incoming']: {
              ...this.getDocumentsPagination?.['incoming'],
              skip: Number((current_page - 1) * take)
            }
          })
        }

        if (take) {
          this.setDocumentsPagination({
            ...this.getDocumentsPagination,
            ['incoming']: {
              ...this.getDocumentsPagination?.['incoming'],
              take
            }
          })
        }
      },

      clearSearchStr() {
        this.setDocumentsPagination({
          ...this.getDocumentsPagination,
          ['incoming']: {
            ...this.getDocumentsPagination?.['incoming'],
            search: ''
          }
        })
      },

      async remove_items(items) {
        this.tableIsBusy = true

        await this.$apollo.mutate({
          mutation: require(`@/views/operational-processes/gql/RemoveIncomingDocument.graphql`),
          variables: {
            ids: items
          }
        })

        this.SET_SELECTED([])

        await this.$apollo.queries.IncomingHeads.refetch()

        this.tableIsBusy = false
      },
      async linkIncoming() {
        await this.$apollo.mutate({
          mutation: require('../../gql/LinkIncomes.graphql'),
          variables: {
            ids: this.getSelectedArr
          }
        })
        this.$apollo.queries.IncomingHeads.refetch()
      },
      async cancel_ttn() {
        this.currentHead = this.document_list.find((el) => el.id === this.getSelectedArr[0])
        this.$bvModal.show('egais-info-modal')
      }
    }
  }
</script>

<style lang="scss" scoped>
  #operproccess__coming {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .b-table__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .b-table__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .modal-footer button {
    margin: 0px !important;
  }
</style>
<!-- <style>
  .modal-footer button {
    margin: 0px !important;
  }
</style> -->
